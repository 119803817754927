<template>
	<div class="copyright_area">
		<footer id="footer_area">
			<div class="container">
				<div class="row">
					<div class="col-lg-3 col-md-6 col-sm-6 col-12">
						<div class="footer_heading_area">
							<h5>{{ $t("need_help") }}</h5>
						</div>
						<div class="footer_first_area">
							<div class="footer_inquery_area">
								<h5>{{ $t("available") }}</h5>
								<h3><a href="tel:+57 604 256 3458">+57 310 8017473</a></h3>
							</div>
							<div class="footer_inquery_area">
								<h5>{{ $t("send_email") }}</h5>
								<h3>
									<a href="mailto:support@domain.com"
										>soporte@selahcomfort.com</a
									>
								</h3>
							</div>
						</div>
					</div>
					<div class="col-lg-2 offset-lg-1 col-md-6 col-sm-6 col-12">
						<div class="footer_heading_area">
							<h5>{{ $t("us") }}</h5>
						</div>
						<div class="footer_link_area">
							<ul>
								<li>
									<router-link to="/about-us">{{ $t("about_us") }}</router-link>
								</li>
								<li>
									<router-link to="/testimonials">{{
										$t("testimonials")
									}}</router-link>
								</li>
							</ul>
						</div>
					</div>
					<div class="col-lg-2 col-md-4 col-sm-6 col-12">
						<div class="footer_heading_area">
							<h5>{{ $t("support") }}</h5>
						</div>
						<div class="footer_link_area">
							<ul>
								<li>
									<router-link to="/faqs">{{
										$t("frequently_asked_questions")
									}}</router-link>
								</li>
								<li>
									<router-link to="/contact">{{
										$t("contact_us")
									}}</router-link>
								</li>
								<li>
									<router-link to="/privacy-policy">{{
										$t("privacy_policies")
									}}</router-link>
								</li>
								<li>
									<router-link to="/terms-service">{{
										$t("terms_and_conditions")
									}}</router-link>
								</li>
							</ul>
						</div>
					</div>
					<div class="col-lg-2 col-md-4 col-sm-6 col-12">
						<div class="footer_heading_area">
							<h5>{{ $t("register_footer") }}</h5>
						</div>
						<div class="footer_link_area">
							<ul>
								<li>
									<a
										href="https://app.selah.agency/register"
										target="_blank"
										rel="noopener noreferrer"
									>
										{{ $t("profitable_unit") }}
									</a>
								</li>
								<li>
									<router-link to="/register-place-interest">{{
										$t("register_place_interest")
									}}</router-link>
								</li>
								<li>
									<router-link to="/register-place-interest">{{
										$t("travel_agency")
									}}</router-link>
								</li>
								<li>
									<router-link to="/register">{{
										$t("trips_in_selah")
									}}</router-link>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</footer>
		<div class="container my-5">
			<div class="row align-items-baseline">
				<div class="co-lg-1 col-md-1 col-sm-12 col-12">
					<img
						class="log-footer"
						src="../assets/img/Selah-Netgativo-409x405.png"
						alt="img"
					/>
				</div>

				<div class="co-lg-1 col-md-1 col-sm-12 col-12">
					<div>
						<h5 class="text-white">{{ $t("follow_us") }}</h5>
						<ul class="social_media_footer d-flex">
							<li>
								<a href="https://www.facebook.com/Selahcomfort"
									><i class="fab fa-facebook-f"></i
								></a>
							</li>
							<!-- <li>
                <a href="#!"> <i class="fa-brands fa-x-twitter"></i></a>
              </li> -->
							<li>
								<a href="https://instagram.com/selahcomfort"
									><i class="fab fa-instagram"></i
								></a>
							</li>
							<!-- <li>
                <a href="#!"><i class="fab fa-linkedin-in"></i></a>
              </li> -->
						</ul>
					</div>
				</div>
				<div class="co-lg-2 col-md-2 col-sm-12 col-12">
					<div class="text-footer-baseline">
						<a class="text-white" href="tel:+57 604 256 3458"
							>+57 604 256 3458</a
						>
					</div>
				</div>
				<div class="co-lg-2 col-md-2 align-items-baseline col-sm-12 col-12">
					<div class="text-footer-baseline">
						<a class="text-white" href="mailto:support@domain.com"
							>admin@selahcomfort.com</a
						>
					</div>
				</div>
				<div class="co-lg-6 col-md-6 col-sm-12 col-12">
					<div class="text-footer-baseline">
						<p class="text-white">
							Copyright <span class="text-coral">©</span> {{ currentYear }}
							{{ $t("copyright") }}
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		name: "Footer",
		data() {
			return {
				currentYear: new Date().getFullYear(),
			};
		},
	};
</script>
