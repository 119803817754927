<template>
  <section id="explore_area" class="section_padding_top">
    <div class="container">
      <!-- Section Heading -->
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <h2 class="text-coral" style="text-align: left">{{ $t("featured") }}</h2>
          <br />
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="theme_nav_tab">
            <nav class="theme_nav_tab_item">
              <div class="nav nav-tabs">
                <button
                  v-for="(category, index) in categories"
                  :key="index"
                  :class="{
                    'nav-link': true,
                    active: selectedCategoryId == category.id,
                  }"
                  type="button"
                  @click="selectCategory(category)"
                  :style="index > 9 ? { margin: '10px' } : { margin: '5px' }"
                >
                  {{ $t(category.text) }}
                </button>
              </div>
            </nav>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="tab-content" id="nav-tabContent">
            <div class="row">
              <div
                v-if="selectedCategory.length > 0"
                class="promotional_tour_slider owl-theme owl-carousel dot_style"
              >
                <swiper
                  :slides-per-view="4"
                  :space-between="20"
                  :pagination="{ clickable: true }"
                >
                  <swiper-slide v-for="(item, index) in selectedCategory" :key="index">
                    <div
                      class="tab-pane fade show active"
                      id="nav-hotels"
                      role="tabpanel"
                      aria-labelledby="nav-hotels-tab"
                    >
                      <div class="theme_common_box_two img_hover">
                        <div class="theme_two_box_img">
                          <router-link
                            :to="{
                              name: `hotel-details`,
                              params: { id: item.id, key: item.key },
                              query: {
                                start_date: defaultStartDate,
                                end_date: defaultEndDate,
                                rooms: rooms,
                                guests: serializeGuests(guests),
                                customerNationality: customerNationality,
                              },
                            }"
                          >
                            <img :src="item.imagen" alt="img" />
                          </router-link>
                        </div>
                        <div class="theme_two_box_content">
                          <h4>
                            <router-link
                              :to="{
                                name: `hotel-details`,
                                params: { id: item.id, key: item.key },
                                query: {
                                  start_date: defaultStartDate,
                                  end_date: defaultEndDate,
                                  rooms: rooms,
                                  guests: serializeGuests(guests),
                                  customerNationality: customerNationality,
                                },
                              }"
                              >{{ item.name }}</router-link
                            >
                          </h4>
                          <h3 class="text-blue-violet">
                            {{ item.price }}
                            <span class="text-black fs-4">{{ $t("per_night") }}</span>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </swiper-slide>
                </swiper>
              </div>
              <div v-else class="col-12">
                <div class="alert text-center">
                  {{ $t("no_properties_message") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.css";
import axios from "axios";
import { API_BASE_URL, OUTSTANDING_PROPERTIES_API, CATEGORIES_API } from "@/api-config";

export default {
  name: "OutstandingProperties",

  components: {
    Swiper,
    SwiperSlide,
  },

  data() {
    const currentDate = new Date();
    const startDate = new Date(currentDate);
    startDate.setDate(currentDate.getDate() + 1);
    const endDate = new Date(currentDate);
    endDate.setDate(currentDate.getDate() + 2);

    return {
      selectedCategoryId: "",
      items: [],
      categories: [],
      defaultStartDate: startDate.toISOString().slice(0, 10),
      defaultEndDate: endDate.toISOString().slice(0, 10),
      customerNationality: "CO",
      rooms: 1,
      guests: [{ adults: 1, children: [] }],
    };
  },
  computed: {
    selectedCategory() {
      return this.items.filter(
        (category) => category.category_id == this.selectedCategoryId
      );
    },
  },

  mounted() {
    this.fetchCategories();
    this.fetchPropertyOutstanding();
  },
  methods: {
    selectCategory(category) {
      this.selectedCategoryId = category.id;
    },

    fetchCategories() {
      axios
        .get(`${API_BASE_URL}${CATEGORIES_API}`)
        .then((response) => {
          this.categories = response.data.results;
          this.selectedCategoryId =
            this.categories.length > 0 ? this.categories[0].id : null;
        })
        .catch((error) => {
          console.error("Error fetching categories:", error);
        });
    },

    serializeGuests(guestsArray) {
      return guestsArray
        .map((guest) => `adults=[${guest.adults}],children=[${guest.children.join(",")}]`)
        .join("&");
    },

    fetchPropertyOutstanding() {
      axios
        .get(`${API_BASE_URL}${OUTSTANDING_PROPERTIES_API}`)
        .then((response) => {
          this.items = response.data.data;
        })
        .catch((error) => {
          console.error("Error fetching properties:", error);
        });
    },
  },
};
</script>
<style scoped>
.theme_common_box_two {
  width: 320px;
  height: max-content;
  border-radius: 15px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  background-color: white;
  font-family: Arial, sans-serif;
}
.theme_two_box_img img {
  width: 100%;
  height: 220px;
  object-fit: cover;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.alert {
  font-size: 30px;
  font-weight: bold;
}
</style>
