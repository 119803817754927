<template>
	<!-- Common Banner Area -->
	<!-- <Banner /> -->

	<!-- Hotel Search Areas -->
	<LicenceSelection />

	<!-- Cta Area 
    <Cta />-->
</template>

<script>
	import LicenceSelection from "@/components/hotel/LicenceSelection.vue";

	export default {
		name: "LicensingView",
		components: {
			LicenceSelection,
		},
	};
</script>
