export function loadGoogleMapsScript() {
	return new Promise((resolve, reject) => {
		const apiKey = process.env.VUE_APP_GOOGLE_MAPS_API_KEY;
		if (!apiKey) {
			reject(new Error("API Key de Google Maps no configurada."));
			return;
		}
		const script = document.createElement("script");
		script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
		script.async = true;
		script.defer = true;
		script.onload = resolve;
		script.onerror = reject;
		document.head.appendChild(script);
	});
}
