<template>
	<section id="licenses_area" class="section_padding">
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-lg-8 text-center">
					<h2>{{ $t("select_license") }}</h2>
					<br />
				</div>
			</div>

			<div class="row justify-content-center">
				<div class="col-12 col-md-8 col-lg-6">
					<div class="text-center mb-4">
						<h3>{{ interestPlace.commercial_name }}</h3>
					</div>
					<div class="main_card">
						<div class="d-flex flex-column ">
							<div
								v-for="(license, index) in licenses"
								:key="index"
								class="w-100"
							>
								<div
									class="license_card"
									:class="{ selected: selectedLicense === license.id }"
									@click="selectLicense(license.id)"
								>
									<div class="license_card_content">
										<h4>{{ license.name }}</h4>
										<p class="text-left" v-if="license.grace_days > 0">
											<strong>Disfruta:</strong> {{ license.grace_days + license.duration }} {{ $t("days") }}
										</p>
										<p>
											<strong>Precio:</strong> {{ formatPrice(license.price) }}
											{{ license.currency }}
										</p>
										<h3 class="text-center fw-bold" v-if="license.saving > 0">{{ $t('Ahorro') }}: {{ license.saving }} %</h3>
										<div class="radio_button text-center mt-3">
											<input
												type="radio"
												:id="'license_' + license.id"
												:value="license.id"
												v-model="selectedLicense"
											/>
											<label :for="'license_' + license.id"></label>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="row justify-content-center mt-4">
							<div class="col-lg-12 text-center">
								<button
									class="btn btn_theme btn_md"
									:disabled="!selectedLicense"
									@click="proceedToPayment"
								>
									{{ $t("pay_license") }}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	import { API_BASE_URL, LICENSES_TYPES, SAVE_LICENSE } from "@/api-config";
	import axios from "axios";
	import Swal from "sweetalert2";
	export default {
		name: "LicensesSelection",
		data() {
			return {
				licenses: [],
				selectedLicense: null,
				id: this.$route.params.id,
				interestPlace: {},
			};
		},
		mounted() {
			this.getLicenses();

			this.paymentCheckout = new PaymentCheckout.modal({
				client_app_code: process.env.VUE_APP_CLIENT_APP_CODE,
				client_app_key: process.env.VUE_APP_CLIENT_APP_KEY,
				locale: process.env.VUE_APP_LOCALE,
				env_mode: process.env.VUE_APP_ENV_MODE,
				onOpen: () => {
					console.log("modal open");
				},
				onClose: () => {
					console.log("modal closed");
				},
				onResponse: (response) => {
					console.log("modal response", response);
					this.handlePaymentResponse(response);
				},
			});
		},
		methods: {
			openPaymentModal(pay_link) {
				fetch(pay_link, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
				})
					.then(async (response) => {
						if (!response.ok) {
							const errorData = await response.json();
							throw new Error(JSON.stringify(errorData));
						}
						return response.json();
					})
					.then((data) => {
						this.paymentCheckout.open(JSON.parse(data.value));
					})
					.catch((error) => {
						let errorData;
						try {
							errorData = JSON.parse(error.message);
						} catch (e) {
							errorData = error.message;
						}
						console.error("Error en la solicitud:", errorData.message);
						this.paymentCheckout.close();
					});

				window.addEventListener("popstate", () => {
					this.paymentCheckout.close();
				});
			},
			handlePaymentResponse(response) {
				const { status, status_detail } = response.transaction;
				if (status === "success" && status_detail === 3) {
					this.showAlert("Licencia pagada exitosamente");
					this.$router.push({ name: "register-place-interest" });
				} else {
					let errorMessage = "Error en el pago. Inténtelo de nuevo.";
					if (status === "failure" && status_detail === 9) {
						errorMessage = "Su pago fue rechazado.";
					} else if (status === "pending" && status_detail === 1) {
						errorMessage = "Su pago se encuentra en revisión.";
					} else if (status === "failure" && status_detail === 11) {
						errorMessage = "Pago rechazado por el sistema antifraude.";
					} else if (status === "failure" && status_detail === 12) {
						errorMessage = "Tarjeta en lista negra.";
					}
					this.showAlert(errorMessage, "error");
				}
			},
			getLicenses() {
				axios
					.get(`${API_BASE_URL}${LICENSES_TYPES}${this.id}`)
					.then((response) => {
						this.licenses = response.data.data;
						this.interestPlace = response.data.interestPlace;
					})
					.catch((error) => {
						console.log(error);
					});
			},

			selectLicense(licenseId) {
				this.selectedLicense = licenseId;
			},

			formatPrice(price) {
				return new Intl.NumberFormat("es-CO", {
					style: "currency",
					currency: "COP",
				}).format(price);
			},

			showAlert(message, type) {
				Swal.fire({
					icon: type,
					title: type === "success" ? "Éxito" : "Error",
					text: message,
				});
			},
			proceedToPayment() {
				axios
					.post(
						`${API_BASE_URL}${SAVE_LICENSE}${this.id}/${this.selectedLicense}`
					)
					.then((response) => {
						if (response.data.url_pay) {
							this.pay_link = response.data.url_pay;
							this.openPaymentModal(this.pay_link);
						}
					})
					.catch((error) => {
						if (
							error.response &&
							error.response.data &&
							error.response.data.message
						) {
							const errorMessage = error.response.data.message;
							this.showAlert(errorMessage, "error");
						} else {
							this.showAlert("Error desconocido");
						}
					});
			},
		},
	};
</script>

<style scoped>
	.main_card {
		background-color: #ffffff;
		border: 1px solid #e0e0e0;
		border-radius: 15px;
		padding: 30px;
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	}

	.license_card {
		border: 1px solid #ddd;
		border-radius: 15px;
		padding: 15px;
		margin: 10px auto;
		cursor: pointer;
		transition: all 0.3s ease;
		position: relative;
		background-color: #f9f9f9;
		max-width: 400px;
		width: 100%;
	}

	.license_card:hover {
		box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
		transform: translateY(-5px);
	}

	.license_card.selected {
		border-color: #007bff;
		background-color: #e9f5ff;
	}

	.license_card_content h4 {
		margin-bottom: 10px;
		font-size: 1.1rem;
		color: #333;
		text-align: center;
	}

	.license_card_content p {
		margin: 5px 0;
		font-size: 0.9rem;
		color: #555;
		text-align: center;
	}

	.discount {
		color: #28a745;
		font-weight: bold;
	}

	.radio_button {
		text-align: center;
		margin-top: 1rem;
	}

	.radio_button input[type="radio"] {
		opacity: 0;
		position: absolute;
	}

	.radio_button label {
		display: inline-block;
		width: 20px;
		height: 20px;
		border: 2px solid #007bff;
		border-radius: 50%;
		position: relative;
		cursor: pointer;
	}

	.radio_button input[type="radio"]:checked + label {
		background-color: #007bff;
	}

	.radio_button label:after {
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background-color: white;
		display: none;
	}

	.radio_button input[type="radio"]:checked + label:after {
		display: block;
	}

	.btn-primary {
		background-color: #007bff;
		border-color: #007bff;
		padding: 10px 20px;
		border-radius: 25px;
		color: white;
		cursor: pointer;
		font-size: 1rem;
		transition: background-color 0.3s ease;
	}

	.btn-primary:hover {
		background-color: #0056b3;
		border-color: #0056b3;
	}

	.btn-primary:disabled {
		background-color: #6c757d;
		border-color: #6c757d;
		cursor: not-allowed;
	}
</style>
