<template>
	<section id="promotional_tours" class="section_padding_top">
		<div class="container">
			<div class="row pb-5">
				<div class="col-lg-12 col-md-12 col-sm-12 col-12">
					<div>
						<router-link to="/place-interest">
							<h2 class="text-coral">{{ $t("places_interest") }}</h2>
						</router-link>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-lg-12">
					<div class="promotional_tour_slider owl-theme owl-carousel dot_style">
						<swiper
							:slides-per-view="4"
							:space-between="20"
							:pagination="{ clickable: true }"
						>
							<swiper-slide v-for="(placeInfo, index) in items" :key="index">
								<div
									class="tab-pane fade show active"
									id="nav-hotels"
									role="tabpanel"
									aria-labelledby="nav-hotels-tab"
								>
									<div class="theme_common_box_two img_hover">
										<div class="theme_two_box_img">
											<img :src="placeInfo.images[0].path" alt="img" />
										</div>
										<div class="theme_two_box_content">
											<h4>
												{{ placeInfo.name }}
											</h4>
											<h3 class="text-coral">
												{{ placeInfo.sub_category.name }}
											</h3>
										</div>
									</div>
								</div>
							</swiper-slide>
						</swiper>
						<div class="d-flex justify-content-end mt-4">
							<a
								href="#"
								@click.prevent="redirectToPlaceInterest"
								class="text-coral font-weight-bold"
								>Ver más</a
							>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	import { Swiper, SwiperSlide } from "swiper/vue";
	import "swiper/swiper-bundle.css";
	import axios from "axios";
	import { API_BASE_URL, HOME_INTEREST_SITES } from "@/api-config";
	import SwiperCore, { Navigation, Mousewheel } from "swiper";

	SwiperCore.use([Navigation, Mousewheel]);

	export default {
		name: "InterestPlaces",
		components: { Swiper, SwiperSlide },
		props: {
			items: {
				type: Array,
				required: true,
			},
			lat: {
				type: Number,
				default: null,
			},
			lng: {
				type: Number,
				default: null,
			},
		},
		data() {
			return {
				swiper: null,
			};
		},
		methods: {
			getSlideClass(index) {
				const classes = ["slide1", "slide2", "slide3"];
				return classes[index % 3];
			},

			redirectToPlaceInterest() {
				this.$router.push({ name: "place-interest" });
			},
		},
	};
</script>

<style scoped>
	.theme_common_box_two {
		width: 320px;
		height: max-content;
		border-radius: 15px;
		box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
		overflow: hidden;
		background-color: white;
		font-family: Arial, sans-serif;
		transition: transform 0.3s ease, box-shadow 0.3s ease;
	}
	.theme_common_box_two:hover {
		transform: translateY(-5px);
		box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
	}

	.theme_two_box_img img {
		width: 100%;
		height: 220px;
		object-fit: cover;
		border-top-left-radius: 15px;
		border-top-right-radius: 15px;
		filter: brightness(0.9);
		transition: filter 0.3s ease;
	}

	.theme_two_box_img img:hover {
		transform: scale(1.05);
	}

	.theme_two_box_content {
		padding: 20px;
		text-align: center;
	}

	.theme_two_box_content h4 {
		font-size: 1.5rem;
		margin-bottom: 10px;
	}

	.theme_two_box_content h3 {
		font-size: 1.2rem;
		margin-bottom: 0;
	}
</style>
