<template>
	<!-- Common Banner Area -->
	<!-- <Banner /> -->

	<!-- Hotel Search Areas -->
	<SitesResult />

	<!-- Cta Area 
    <Cta />-->
</template>

<script>
	import SitesResult from "@/components/hotel/SitesResult.vue";

	export default {
		name: "PlaceInterestView",
		components: {
			SitesResult,
		},
	};
</script>
