<template>
	<section id="explore_area" class="section_padding">
		<div class="container">
			<!-- Section Heading -->
			<div class="row">
				<div class="col-lg-3 col-md-3 col-sm-12 col-12">
					<h2>{{ $t("filters") }}</h2>
					<br />
				</div>
				<div class="col-lg-6 col-md-6 col-sm-12 col-12">
					<h2>{{ $t("properties_found") }}</h2>
					<br />
				</div>
				<div class="col-lg-3 col-md-3 col-sm-12 col-12">
					<p>
						<a class="text-coral" href="#" @click.prevent="redirectToInterestSites">
							{{ $t("places_interest_recommended") }}
						</a>
					</p>
					<br />
				</div>
			</div>
			<div class="row">
				<div class="col-lg-3">
					<div class="left_side_search_area">
						<div class="left_side_search_boxed">
							<div class="left_side_search_heading">
								<h5>{{ $t("filter_by_amenities") }}</h5>
							</div>
							<div class="filter_review">
								<div class="form-group">
									<v-select
										class="form-control bg_input"
										multiple
										label="title"
										value="slug"
										v-model="selectedAmenities"
										:options="amenitiesItems"
										:placeholder="$t('select_amenities')"
									>
									</v-select>
								</div>
							</div>
							<br />
							<button
								class="apply"
								type="button"
								:disabled="isLoading"
								@click="applyFilters()"
							>
								<span v-if="!isLoading">
									{{ $t("apply") }}
									<i class="px-2 fas fa-angle-right text-coral"></i>
								</span>
								<div v-else class="loader"></div>
							</button>
						</div>
						<div class="left_side_search_boxed">
							<div class="left_side_search_heading">
								<h5>{{ $t("filter_by_price") }}</h5>
							</div>
							<div class="form-group">
								{{ $t("from") }}
								<input
									type="number"
									class="form-control bg_input"
									v-model="min"
									@input="validateInput"
								/>
							</div>
							<br />
							<div class="form-group">
								{{ $t("to") }}
								<input
									type="number"
									class="form-control bg_input"
									v-model="max"
									@input="validateInput"
								/>
							</div>
							<br />
							<button
								class="apply"
								type="button"
								:disabled="isLoading"
								@click="applyFilters()"
							>
								<span v-if="!isLoading">
									{{ $t("apply") }}
									<i class="px-2 fas fa-angle-right text-coral"></i>
								</span>
								<div v-else class="loader"></div>
							</button>
						</div>
						<div class="left_side_search_boxed">
							<div class="left_side_search_heading">
								<h5>{{ $t("filter_by_review") }}</h5>
							</div>
							<div class="filter_review">
								<form class="review_star">
									<div class="form-check">
										<input
											class="form-check-input"
											type="checkbox"
											value="5"
											id="flexCheckDefault"
											v-model="reviewFilter"
										/>
										<label class="form-check-label" for="flexCheckDefault">
											<i class="fas fa-star review-star-color"></i>
											<i class="fas fa-star review-star-color"></i>
											<i class="fas fa-star review-star-color"></i>
											<i class="fas fa-star review-star-color"></i>
											<i class="fas fa-star review-star-color"></i>
										</label>
									</div>
									<div class="form-check">
										<input
											class="form-check-input"
											type="checkbox"
											value="4"
											id="flexCheckDefault1"
											v-model="reviewFilter"
										/>
										<label class="form-check-label" for="flexCheckDefault1">
											<i class="fas fa-star review-star-color"></i>
											<i class="fas fa-star review-star-color"></i>
											<i class="fas fa-star review-star-color"></i>
											<i class="fas fa-star review-star-color"></i>
										</label>
									</div>
									<div class="form-check">
										<input
											class="form-check-input"
											type="checkbox"
											value="3"
											id="flexCheckDefault2"
											v-model="reviewFilter"
										/>
										<label class="form-check-label" for="flexCheckDefault2">
											<i class="fas fa-star review-star-color"></i>
											<i class="fas fa-star review-star-color"></i>
											<i class="fas fa-star review-star-color"></i>
										</label>
									</div>
									<div class="form-check">
										<input
											class="form-check-input"
											type="checkbox"
											value="2"
											id="flexCheckDefault3"
											v-model="reviewFilter"
										/>
										<label class="form-check-label" for="flexCheckDefault3">
											<i class="fas fa-star review-star-color"></i>
											<i class="fas fa-star review-star-color"></i>
										</label>
									</div>
									<div class="form-check">
										<input
											class="form-check-input"
											type="checkbox"
											value="1"
											id="flexCheckDefault5"
											v-model="reviewFilter"
										/>
										<label class="form-check-label" for="flexCheckDefault5">
											<i class="fas fa-star review-star-color"></i>
										</label>
									</div>
									<br />
									<button
										class="apply"
										type="button"
										:disabled="isLoading"
										@click="applyFilters()"
									>
										<span v-if="!isLoading">
											{{ $t("apply") }}
											<i class="px-2 fas fa-angle-right text-coral"></i>
										</span>
										<div v-else class="loader"></div>
									</button>
								</form>
							</div>
						</div>
						<div class="left_side_search_boxed">
							<div class="left_side_search_heading">
								<h5>{{ $t("filter_by_hotel_star") }}</h5>
							</div>
							<div class="filter_review">
								<form class="review_star">
									<div class="form-check">
										<input
											class="form-check-input"
											type="checkbox"
											value="5"
											id="flexCheckDefaulta"
											v-model="scoreFilter"
										/>
										<label class="form-check-label" for="flexCheckDefaulta">
											<i class="fas fa-star hotel-star-color"></i>
											<i class="fas fa-star hotel-star-color"></i>
											<i class="fas fa-star hotel-star-color"></i>
											<i class="fas fa-star hotel-star-color"></i>
											<i class="fas fa-star hotel-star-color"></i>
										</label>
									</div>
									<div class="form-check">
										<input
											class="form-check-input"
											type="checkbox"
											value="4"
											id="flexCheckDefaulf21"
											v-model="scoreFilter"
										/>
										<label class="form-check-label" for="flexCheckDefaulf2">
											<i class="fas fa-star hotel-star-color"></i>
											<i class="fas fa-star hotel-star-color"></i>
											<i class="fas fa-star hotel-star-color"></i>
											<i class="fas fa-star hotel-star-color"></i>
										</label>
									</div>
									<div class="form-check">
										<input
											class="form-check-input"
											type="checkbox"
											value="3"
											id="flexCheckDefaultf3"
											v-model="scoreFilter"
										/>
										<label class="form-check-label" for="flexCheckDefaultf3">
											<i class="fas fa-star hotel-star-color"></i>
											<i class="fas fa-star hotel-star-color"></i>
											<i class="fas fa-star hotel-star-color"></i>
										</label>
									</div>
									<div class="form-check">
										<input
											class="form-check-input"
											type="checkbox"
											value="2"
											id="flexCheckDefaultf4"
											v-model="scoreFilter"
										/>
										<label class="form-check-label" for="flexCheckDefaultf4">
											<i class="fas fa-star hotel-star-color"></i>
											<i class="fas fa-star hotel-star-color"></i>
										</label>
									</div>
									<div class="form-check">
										<input
											class="form-check-input"
											type="checkbox"
											value="1"
											id="flexCheckDefaultf5"
											v-model="scoreFilter"
										/>
										<label class="form-check-label" for="flexCheckDefaultf5">
											<i class="fas fa-star hotel-star-color"></i>
										</label>
									</div>
									<br />
									<button
										class="apply"
										type="button"
										:disabled="isLoading"
										@click="applyFilters()"
									>
										<span v-if="!isLoading">
											{{ $t("apply") }}
											<i class="px-2 fas fa-angle-right text-coral"></i>
										</span>
										<div v-else class="loader"></div>
									</button>
								</form>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-9">
					<div class="row">
						<div
							v-for="(hotelInfo, index) in itemsResult"
							:key="index"
							class="col-lg-4 col-md-6 col-sm-12"
						>
							<div class="theme_common_box_two img_hover">
								<div class="theme_two_box_img" @click="openInNewTab(hotelInfo)">
									<img :src="hotelInfo.imagen" :alt="hotelInfo.name" />
								</div>
								<div
									class="theme_two_box_content"
									@click="openInNewTab(hotelInfo)"
								>
									<h4>
										{{ hotelInfo.name }}
									</h4>
									<p>
										<span class="score_rating"
											><div class="score_star_all">
												<span v-show="hotelInfo.scores > 0">
													{{ $t("category") }}
													<i
														v-show="hotelInfo.scores > 0"
														class="fas fa-star"
														v-for="n in hotelInfo.scores"
														:key="n"
													></i>
												</span>
											</div>
										</span>
									</p>
									<span> {{ $t("prices_from") }} </span>
									<h3 class="text-blue-violet">
										{{ hotelInfo.currency
										}}{{ formatNumber(hotelInfo.min_price)
										}}<span class="text-black fs-4">{{ $t("per_night") }}</span>
									</h3>

									<span class="review_rating"
										><div class="review_star_all">
											<span v-show="hotelInfo.reviews > 0" class="review_count"
												>{{ $t("review") }}
												<i
													v-show="hotelInfo.reviews > 0"
													class="fas fa-star"
													v-for="m in hotelInfo.reviews"
													:key="m"
												></i
											></span>
										</div>
									</span>
								</div>
							</div>
						</div>
						<div class="col-lg-9">
							<div class="pagination_area">
								<ul class="pagination">
									<li
										class="page-item"
										:class="{ disabled: currentPage === 1 }"
									>
										<a
											class="page-link"
											@click="prev(currentPage - 1)"
											:disabled="currentPage === 1"
											aria-label="Previous"
										>
											<span aria-hidden="true">&laquo;</span>
											<span class="sr-only">Previous</span>
										</a>
									</li>
									<li
										class="page-item"
										v-for="page in paginationLinks.pages"
										:key="page.label"
										:class="{ active: page.active }"
									>
										<a
											class="page-link"
											@click="next(page.label)"
											v-if="page.url !== null"
											>{{ page.label }}</a
										>
										<span class="page-link" v-else>{{ page.label }}</span>
									</li>
									<li
										class="page-item"
										:class="{ disabled: currentPage === totalPages }"
									>
										<a
											class="page-link"
											@click="next(currentPage + 1)"
											:disabled="currentPage === totalPages"
											aria-label="Next"
										>
											<span aria-hidden="true">&raquo;</span>
											<span class="sr-only">Next</span>
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
	import Slider from "@vueform/slider";
	import { mapState, mapActions } from "vuex";
	import { API_BASE_URL, HAS_INTEREST_SITES } from "@/api-config";
	import axios from "axios";

	export default {
		name: "SearchResult",
		components: {
			Slider,
		},
		data() {
			return {
				scoreFilter: [],
				reviewFilter: [],
				amenitiesItems: [],
				selectedAmenities: [],
				min: "",
				max: "",
				priceFilteredItems: [],
				parsedGuests: [],
				filteredItems: [],
				isLoading: false,
				city_id: 0,
				sites: {},
			};
		},
		computed: {
			...mapState({
				guests: (state) => state.form.guests,
				paginationLinks: (state) => state.form.paginationLinks,
				currentPage: (state) => state.form.currentPage,
				totalPages: (state) => state.form.totalPages,
				customerNationality: (state) => state.form.customerNationality,
			}),
			...mapState("form", ["items", "amenities"]),
			itemsResult() {
				this.filtered = this.priceFilteredItems;
				// return this.applyFilters(this.priceFilteredItems);
				return this.filtered;
			},
			amenitiesResult() {
				if (this.selectedAmenities.length > 0) {
					return this.amenitiesItems.filter((item) =>
						this.selectedAmenities.every((selectedAmenity) =>
							item.amenities.includes(selectedAmenity)
						)
					);
				} else {
					return this.amenitiesItems;
				}
			},
		},

		async mounted() {
			this.city_id = this.$route.query.city_id;
			this.hasInterestSites();
			this.parsedGuests = this.deserializeGuests(this.$route.query.guests);
			this.priceFilteredItems = this.items;
			this.amenitiesItems = this.amenities;
			if (this.items.length == 0) {
				let queryParameters = {
					start_date: this.$route.query.start_date,
					end_date: this.$route.query.end_date,
					guests: this.deserializeGuests(this.$route.query.guests),
					city: this.$route.query.city,
					country: this.$route.query.country,
					rooms: 0,
					customerNationality: this.$route.query.customerNationality,
					min_price: this.min || null,
					max_price: this.max || null,
					reviews: this.reviewFilter.length > 0 ? this.reviewFilter : null,
					scores: this.scoreFilter.length > 0 ? this.scoreFilter : null,
					amenities:
						this.selectedAmenities.length > 0 ? this.selectedAmenities : null,
				};

				this.parsedGuests = queryParameters.guests;
				queryParameters.rooms = queryParameters.guests.length;

				let data = await this.fetchProperties(queryParameters);
				this.priceFilteredItems = data.properties.data;
				this.amenitiesItems = data.amenities;
			}
		},
		methods: {
			...mapActions("form", ["fetchProperties"]),
			async applyFilters() {
				this.isLoading = true;
				let queryParameters = {
					start_date: this.$route.query.start_date,
					end_date: this.$route.query.end_date,
					guests: this.deserializeGuests(this.$route.query.guests),
					city: this.$route.query.city,
					country: this.$route.query.country,
					rooms: 0,
					customerNationality: this.$route.query.customerNationality,
					min_price: this.min || null,
					max_price: this.max || null,
					reviews: this.reviewFilter.length > 0 ? this.reviewFilter : [],
					scores: this.scoreFilter.length > 0 ? this.scoreFilter : [],
					amenities:
						this.selectedAmenities.length > 0
							? this.selectedAmenities.map((amenity) => amenity.slug)
							: [],
				};

				this.parsedGuests = queryParameters.guests;
				queryParameters.rooms = queryParameters.guests.length;
				console.log(queryParameters);

				try {
					let data = await this.fetchProperties(queryParameters);
					this.priceFilteredItems = data.properties.data || [];
					this.amenitiesItems = data.amenities || [];
				} catch (error) {
					console.error("Error fetching properties:", error);
				} finally {
					this.isLoading = false;
				}
			},

			validateInput() {
				if (this.min <= 0 || isNaN(this.min)) {
					this.min = "";
				}
				if (this.max <= 0 || isNaN(this.max)) {
					this.max = "";
				}
			},

			hasInterestSites() {
				axios
					.get(`${API_BASE_URL}${HAS_INTEREST_SITES}?city_id=${this.city_id}`)
					.then((response) => {
						this.sites = response.data;
					})
					.catch((error) => {
						console.log(error);
					});
			},

			redirectToInterestSites() {
				const { city_id, state_id } = this.sites;

				let routeParams = {};

				if (city_id && state_id) {
					routeParams = { city_id };
				} else if (city_id) {
					routeParams = { city_id };
				} else if (state_id) {
					routeParams = { state_id };
				}

				console.log("parametros", routeParams);

				const url = this.$router.resolve({
					name: "place-interest",
					query: routeParams,
				}).href;

				window.open(url, "_blank", "noopener,noreferrer");
			},

			serializeGuests(guestsArray) {
				return guestsArray
					.map(
						(guest) =>
							`adults=[${guest.adults}],children=[${guest.children.join(",")}]`
					)
					.join("&");
			},

			deserializeGuests(guestsString) {
				if (!guestsString) return [];
				return guestsString.split("&").map((guest) => {
					const adultsMatch = guest.match(/adults=\[(\d+)\]/);
					const childrenMatch = guest.match(/children=\[([\d,]*)\]/);

					return {
						adults: adultsMatch ? parseInt(adultsMatch[1], 10) : 0,
						children:
							childrenMatch && childrenMatch[1]
								? childrenMatch[1].split(",").map(Number)
								: [],
					};
				});
			},

			openInNewTab(hotelInfo) {
				const url = this.$router.resolve({
					name: "hotel-details",
					params: { id: hotelInfo.id, key: hotelInfo.key },
					query: {
						start_date: this.$route.query.start_date,
						end_date: this.$route.query.end_date,
						rooms: this.$route.query.rooms,
						guests: this.$route.query.guests,
						customerNationality: this.$route.query.customerNationality,
					},
				}).href;

				window.open(url, "_blank", "noopener,noreferrer");
			},

			async next(page) {
				let queryParameters = {
					start_date: this.$route.query.start_date,
					end_date: this.$route.query.end_date,
					city: this.$route.query.city,
					country: this.$route.query.country,
					// guests: this.parsedGuests,
					guests: this.parsedGuests,
					rooms: 0,
					customerNationality: this.$route.query.customerNationality,
					min_price: this.min || null,
					max_price: this.max || null,
					reviews: this.reviewFilter.length > 0 ? this.reviewFilter : [],
					scores: this.scoreFilter.length > 0 ? this.scoreFilter : [],
					amenities:
						this.selectedAmenities.length > 0
							? this.selectedAmenities.map((amenity) => amenity.slug)
							: [],
					page: page,
				};

				queryParameters.rooms = queryParameters.guests.length;

				console.log(queryParameters);

				try {
					let data = await this.fetchProperties(queryParameters);
					this.priceFilteredItems = data.properties.data || [];
					this.amenitiesItems = data.amenities || [];

					queryParameters.guests = serializeGuests(queryParameters.guests);
					this.$router.push({ name: "hotel-search", query: queryParameters });
				} catch (error) {
					console.error("Error fetching properties:", error);
				}
			},

			async prev(page) {
				let queryParameters = {
					start_date: this.$route.query.start_date,
					end_date: this.$route.query.end_date,
					city: this.$route.query.city,
					country: this.$route.query.country,
					guests: this.parsedGuests,
					rooms: 0,
					customerNationality: this.$route.query.customerNationality,
					min_price: this.min || null,
					max_price: this.max || null,
					reviews: this.reviewFilter.length > 0 ? this.reviewFilter : [],
					scores: this.scoreFilter.length > 0 ? this.scoreFilter : [],
					amenities:
						this.selectedAmenities.length > 0
							? this.selectedAmenities.map((amenity) => amenity.slug)
							: [],
					page: page,
				};

				try {
					let data = await this.fetchProperties(queryParameters);
					this.priceFilteredItems = data.properties.data || [];
					this.amenitiesItems = data.amenities || [];

					this.$router.push({ name: "hotel-search", query: queryParameters });
				} catch (error) {
					console.error("Error fetching properties:", error);
				}
			},

			formatNumber(number) {
				return new Intl.NumberFormat("es-ES", {
					style: "decimal",
					minimumFractionDigits: 0,
					maximumFractionDigits: 2,
					useGrouping: true,
				}).format(number);
			},
		},
	};
</script>

<style scoped>
	.loader {
		border: 3px solid #f3f3f3;
		border-top: 3px solid #3498db;
		border-radius: 50%;
		width: 18px; /* Tamaño más pequeño */
		height: 18px;
		animation: spin 1s linear infinite;
		display: inline-block;
	}

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}

	.apply {
		background-color: #ff6155;
		color: #fff;
		width: 100%;
		height: 40px;
		font-size: 16px;
		border-radius: 15px;
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 10px; /* Espacio entre texto e ícono/loader */
		position: relative;
	}

	.theme_common_box_two {
		width: 320px;
		height: max-content;
		border-radius: 15px;
		box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
		overflow: hidden;
		background-color: white;
		font-family: Arial, sans-serif;
	}

	.theme_two_box_img {
		position: relative;
		overflow: hidden;
		border-top-left-radius: 15px;
		border-top-right-radius: 15px;
	}

	.theme_two_box_img img {
		width: 100%;
		height: 220px;
		object-fit: cover;
		border-top-left-radius: 15px;
		border-top-right-radius: 15px;
		cursor: pointer;
	}

	.theme_two_box_content {
		padding: 10px 15px;
		text-align: left;
		margin-top: -5px;
	}

	.theme_two_box_content h4 {
		font-size: 18px;
		font-weight: bold;
		color: #333;
		margin: 5px 0;
	}

	.theme_two_box_content .score_rating .score_star_all i {
		color: #5d69d5;
		font-size: 14px;
	}

	.theme_two_box_content .review_rating .review_star_all i {
		color: #ffbc00;
		font-size: 14px;
	}

	.theme_two_box_content .text-blue-violet {
		font-size: 20px;
		font-weight: bold;
		color: #5d69d5;
		margin: 10px 0;
	}

	.theme_two_box_content .fs-4 {
		font-size: 14px;
		color: #333;
	}

	.theme_two_box_content .review_count {
		font-size: 14px;
		color: #333;
	}

	.review_star_all .fas.fa-star {
		color: #5d69d5;
		margin-left: 2px;
	}

	.review-star-color {
		color: #f1c40f;
	}

	.hotel-star-color {
		color: #5d69d5;
	}

	.v-select {
		height: auto;
	}

	.v-select .vs__dropdown-menu {
		max-height: 200px;
		overflow-y: auto;
	}

	.v-select .vs__selected-options {
		display: flex;
		flex-wrap: wrap;
		gap: 5px;
		max-height: 200px;
		overflow-y: auto;
	}
</style>
