<template>
	<!-- Common Banner Area -->
	<Banner />

	<!--  Common Author Area -->
	<CommonAuthorSix />
</template>
<script>
	import Banner from "@/components/owner/Banner.vue";
	import CommonAuthorSix from "@/components/user/CommonAuthorSix.vue";
	export default {
		name: "RegisterPlaceInterestView",
		components: {
			Banner,
			CommonAuthorSix,
		},
	};
</script>
