<template>
	<section id="explore_area" class="section_padding">
		<div class="container">
			<!-- Section Heading -->
			<div class="row">
				<div class="col-lg-3 col-md-3 col-sm-12 col-12">
					<h2>{{ $t("filters") }}</h2>
					<br />
				</div>
				<div class="col-lg-9 col-md-9 col-sm-12 col-12">
					<h2>{{ $t("places_found") }}</h2>
					<br />
				</div>
			</div>
			<div class="row">
				<div class="col-lg-3">
					<div class="left_side_search_area">
						<div class="left_side_search_boxed">
							<div class="left_side_search_heading">
								<h5>{{ $t("filter_by_categories") }}</h5>
							</div>
							<div class="filter_review">
								<div class="form-group">
									<v-select
										class="form-control bg_input"
										label="text"
										:reduce="(category) => category"
										v-model="selectedCategory"
										:options="categories"
										:placeholder="$t('select_category')"
									>
									</v-select>
								</div>
							</div>
							<br />
							<button
								class="apply"
								type="button"
								:disabled="isLoading"
								@click="applyFilters()"
							>
								<span v-if="!isLoading">
									{{ $t("apply") }}
									<i class="px-2 fas fa-angle-right text-coral"></i>
								</span>
								<div v-else class="loader"></div>
							</button>
						</div>
						<div class="left_side_search_boxed">
							<div class="left_side_search_heading">
								<h5>{{ $t("filter_by_subcategories") }}</h5>
							</div>
							<div class="filter_review">
								<div class="form-group">
									<v-select
										class="form-control bg_input"
										label="text"
										:reduce="(subcategory) => subcategory.id"
										v-model="selectedSubCategory"
										:options="subcategories"
										:placeholder="$t('select_subcategory')"
									>
									</v-select>
								</div>
							</div>
							<br />
							<button
								class="apply"
								type="button"
								:disabled="isLoading"
								@click="applyFilters()"
							>
								<span v-if="!isLoading">
									{{ $t("apply") }}
									<i class="px-2 fas fa-angle-right text-coral"></i>
								</span>
								<div v-else class="loader"></div>
							</button>
							<p
								v-if="selectedCategory && !selectedSubCategory"
								class="text-coral"
							>
								{{ $t("subcategory_required") }}
							</p>
						</div>
						<div class="left_side_search_boxed">
							<div class="left_side_search_heading">
								<h5>{{ $t("filter_by_average_investment") }}</h5>
							</div>
							<div class="form-group">
								<span class="multi-range">
									<input
										type="range"
										:min="minRange"
										:max="maxRange"
										:value="minInvestment"
										@input="updateMinInvestment"
										id="lower"
									/>
									<input
										class="position_ball"
										type="range"
										:min="minRange"
										:max="maxRange"
										:value="maxInvestment"
										@input="updateMaxInvestment"
										id="upper"
									/>
								</span>
								<div class="range-values">
									<span>{{ formatNumber(minInvestment) }}</span>
									<span>{{ formatNumber(maxInvestment) }}</span>
								</div>
							</div>
							<br />
							<button
								class="apply"
								type="button"
								:disabled="isLoading"
								@click="applyFilters()"
							>
								<span v-if="!isLoading">
									{{ $t("apply") }}
									<i class="px-2 fas fa-angle-right text-coral"></i>
								</span>
								<div v-else class="loader"></div>
							</button>
						</div>
						<div class="left_side_search_boxed">
							<div class="left_side_search_heading">
								<h5>{{ $t("filter_by_cities") }}</h5>
							</div>
							<div class="filter_review">
								<div class="form-group">
									<v-select
										class="form-control bg_input"
										label="text"
										multiple
										:reduce="(city) => city.id"
										v-model="selectedCities"
										:options="cities"
										:placeholder="$t('select_cities')"
									>
									</v-select>
								</div>
							</div>
							<br />
							<button
								class="apply"
								type="button"
								:disabled="isLoading"
								@click="applyFilters()"
							>
								<span v-if="!isLoading">
									{{ $t("apply") }}
									<i class="px-2 fas fa-angle-right text-coral"></i>
								</span>
								<div v-else class="loader"></div>
							</button>
						</div>
						<div class="left_side_search_boxed">
							<div class="left_side_search_heading">
								<h5>{{ $t("filter_by_departments") }}</h5>
							</div>
							<div class="filter_review">
								<div class="form-group">
									<v-select
										class="form-control bg_input"
										label="text"
										multiple
										:reduce="(state) => state.id"
										v-model="selectedDepartments"
										:options="departments"
										:placeholder="$t('select_departments')"
									>
									</v-select>
								</div>
							</div>
							<br />
							<button
								class="apply"
								type="button"
								:disabled="isLoading"
								@click="applyFilters()"
							>
								<span v-if="!isLoading">
									{{ $t("apply") }}
									<i class="px-2 fas fa-angle-right text-coral"></i>
								</span>
								<div v-else class="loader"></div>
							</button>
						</div>
					</div>
				</div>
				<div class="col-lg-9">
					<div class="row">
						<div
							v-for="(item, index) in filteredData"
							:key="index"
							class="col-lg-4 col-md-6 col-sm-12"
						>
							<div class="theme_common_box_two img_hover">
								<div class="theme_two_box_img">
									<img
										:src="item.images[0].path"
										:alt="item.category.name"
										@click="openModal(item)"
									/>
								</div>
								<div class="theme_two_box_content">
									<h4>
										{{ item.name }}
									</h4>
								</div>
							</div>
						</div>
						<div class="col-lg-9">
							<div class="pagination_area">
								<ul class="pagination">
									<li
										class="page-item"
										:class="{ disabled: meta.current_page === 1 }"
									>
										<a
											class="page-link"
											@click="prev"
											:disabled="meta.current_page === 1"
											aria-label="Previous"
										>
											<span aria-hidden="true">&laquo;</span>
											<span class="sr-only">Previous</span>
										</a>
									</li>

									<li
										class="page-item"
										v-for="page in meta.last_page"
										:key="page"
										:class="{ active: meta.current_page === page }"
									>
										<a class="page-link" @click="goToPage(page)">{{ page }}</a>
									</li>

									<li
										class="page-item"
										:class="{ disabled: meta.current_page === meta.last_page }"
									>
										<a
											class="page-link"
											@click="next"
											:disabled="meta.current_page === meta.last_page"
											aria-label="Next"
										>
											<span aria-hidden="true">&raquo;</span>
											<span class="sr-only">Next</span>
										</a>
									</li>
								</ul>
							</div>
						</div>

						<div
							v-if="showModal"
							class="modal-overlay"
							@click.self="closeModal"
						>
							<div class="modal-content">
								<div class="modal-header">
									<button @click="closeModal" class="close-btn">&times;</button>
								</div>
								<div class="modal-body">
									<div v-if="selectedItem">
										<div class="swiper-container">
											<div class="swiper-wrapper">
												<div
													v-for="(image, index) in selectedItem.images"
													:key="index"
													class="swiper-slide"
												>
													<img
														:src="image.path"
														:alt="selectedItem.category.name"
														class="img-fluid"
													/>
												</div>
											</div>
											<div class="swiper-button-next"></div>
											<div class="swiper-button-prev"></div>
										</div>
										<div class="modal-info">
											<div class="title-section">
												<h2>{{ selectedItem.name }}</h2>
												<div class="contact-icons">
													<a
														:href="`https://wa.me/${selectedItem.whatsapp}`"
														target="_blank"
														class="contact-icon whatsapp"
													>
														<i class="fab fa-whatsapp"></i>
													</a>
													<a
														:href="selectedItem.contact_link"
														target="_blank"
														class="contact-icon website"
														@mouseover="hoverText = $t('more_info')"
														@mouseleave="hoverText = ''"
													>
														<i class="fas fa-external-link-alt"></i>
														<span v-if="hoverText" class="hover-text">{{
															hoverText
														}}</span>
													</a>
												</div>
											</div>
											<p class="category">
												{{ selectedItem.category.name }} -
												{{ selectedItem.sub_category.name }}
											</p>
											<p class="location">
												<strong>Ubicación:</strong>
												{{ selectedItem.state.name }} -
												{{ selectedItem.city_id.name }}
												<a
													:href="`https://www.google.com/maps?q=${selectedItem.latitude},${selectedItem.longitude}`"
													target="_blank"
													class="maps-link"
												>
													<i class="fas fa-map-marker-alt"></i>
												</a>
											</p>
											<p class="investment">
												<strong>Inversión Promedio:</strong> ${{
													formatNumber(selectedItem.average_investment)
												}}
											</p>
											<p class="phone">
												<strong>Teléfono:</strong> {{ selectedItem.phone }}
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
	import axios from "axios";
	import {
		API_BASE_URL,
		SITES_INTEREST_API,
		CITY_API,
		STATE_API,
		PLACE_CATEGORIES_API,
		PLACE_SUBCATEGORIES_API,
	} from "@/api-config";
	import Swiper from "swiper";
	import "swiper/swiper-bundle.css";

	export default {
		name: "SitesResult",
		data() {
			return {
				cities: [],
				departments: [],
				selectedCities: null,
				selectedDepartments: null,
				categories: [],
				selectedCategory: null,
				subcategories: [],
				selectedSubCategory: null,
				minInvestment: 0,
				maxInvestment: 0,
				minRange: 0,
				maxRange: 0,
				apiData: [],
				filteredData: [],
				selectedItem: null,
				showModal: false,
				hoverText: "",
				meta: {
					current_page: 1,
					last_page: 1,
					min_investment: 0,
					max_investment: 0,
				},
				links: {},
				isLoading: false,
				city_id: 0,
				state_id: 0,
			};
		},

		async mounted() {
			this.city_id = this.$route.query.city_id;
			this.state_id = this.$route.query.state_id;
			await this.fetchData();
			this.getCities();
			this.getStates();
			this.getCategories();

			if (this.city_id) {
				this.selectedCities = [Number(this.city_id)];
				this.applyFilters();
			}
			if (this.state_id) {
				this.selectedDepartments = [Number(this.state_id)];
				this.applyFilters();
			}
		},

		watch: {
			"$route.query.city_id"(newCityId) {
				if (newCityId) {
					this.selectedCities = [Number(newCityId)];
					this.applyFilters();
				} else {
					this.selectedCities = null;
					this.applyFilters();
				}
			},
			"$route.query.state_id"(newStateId) {
				if (newStateId) {
					this.selectedDepartments = [Number(newStateId)];
					this.applyFilters();
				} else {
					this.selectedDepartments = null;
					this.applyFilters();
				}
			},
			selectedCategory(newCategory) {
				if (newCategory) {
					this.getSubcategories(newCategory.id);
					this.selectedSubCategory = null;
					this.subcategories = [];
					this.selectedSubCategory = null;
				}
			},
		},

		methods: {
			async fetchData(page = 1) {
				try {
					const response = await axios.get(
						`${API_BASE_URL}${SITES_INTEREST_API}?page=${page}`
					);
					this.apiData = response.data.data;
					this.filteredData = this.apiData;
					this.meta = response.data.meta;
					this.links = response.data.links;

					this.minRange = this.meta.min_investment;
					this.maxRange = this.meta.max_investment;

					this.minInvestment = this.minRange;
					this.maxInvestment = this.maxRange;
				} catch (error) {
					console.error("Error fetching data:", error);
				} finally {
					this.isLoading = false;
				}
			},
			getCities() {
				axios
					.get(`${API_BASE_URL}${CITY_API}&has=interestPlaces`)
					.then((response) => {
						this.cities = response.data.results;
					})
					.catch((error) => {
						console.log(error);
					});
			},

			getStates() {
				axios
					.get(`${API_BASE_URL}${STATE_API}&has=interestPlaces`)
					.then((response) => {
						this.departments = response.data.results;
					})
					.catch((error) => {
						console.log(error);
					});
			},

			getCategories() {
				axios
					.get(`${API_BASE_URL}${PLACE_CATEGORIES_API}?has=interestPlaces`)
					.then((response) => {
						this.categories = response.data.results;
					})
					.catch((error) => {
						console.log(error);
					});
			},
			getSubcategories(categoryId) {
				axios
					.get(
						`${API_BASE_URL}${PLACE_SUBCATEGORIES_API}?category_id=${categoryId}&has=interestPlaces`
					)
					.then((response) => {
						this.subcategories = response.data.results;
					})
					.catch((error) => {
						console.log(error);
					});
			},
			applyFilters() {
				let filtered = this.apiData;

				if (this.selectedSubCategory) {
					filtered = filtered.filter(
						(item) => item.sub_category.id === this.selectedSubCategory
					);
				}

				if (
					this.minInvestment != null &&
					this.maxInvestment != null &&
					!isNaN(this.minInvestment) &&
					!isNaN(this.maxInvestment)
				) {
					filtered = filtered.filter(
						(item) =>
							item.average_investment >= this.minInvestment &&
							item.average_investment <= this.maxInvestment
					);
				}

				if (this.selectedCities && this.selectedCities.length > 0) {
					filtered = filtered.filter((item) =>
						this.selectedCities.includes(item.city_id.id)
					);
				}

				if (this.selectedDepartments && this.selectedDepartments.length > 0) {
					filtered = filtered.filter((item) =>
						this.selectedDepartments.includes(item.state.id)
					);
				}

				this.filteredData = filtered;
			},

			validateInput() {
				if (this.minInvestment <= 0 || isNaN(this.minInvestment)) {
					this.minInvestment = "";
				}
				if (this.maxInvestment <= 0 || isNaN(this.maxInvestment)) {
					this.maxInvestment = "";
				}
			},

			updateMinInvestment(event) {
				const value = parseInt(event.target.value);
				const upperValue = this.maxInvestment;

				if (value >= upperValue) {
					this.minInvestment = upperValue - 20000;
				} else {
					this.minInvestment = value;
				}

				if (this.minInvestment === this.minRange) {
					this.maxInvestment = Math.max(
						this.minRange + 20000,
						this.maxInvestment
					);
				}
			},

			updateMaxInvestment(event) {
				const value = parseInt(event.target.value);
				const lowerValue = this.minInvestment;

				if (value <= lowerValue) {
					this.maxInvestment = lowerValue + 20000;
				} else {
					this.maxInvestment = value;
				}

				if (this.maxInvestment === this.maxRange) {
					this.minInvestment = Math.min(
						this.maxRange - 20000,
						this.minInvestment
					);
				}
			},

			// updateMinInvestment(event) {
			// 	const value = parseInt(event.target.value);
			// 	if (value > this.maxInvestment - 20000) {
			// 		this.minInvestment = this.maxInvestment - 20000;
			// 	} else {
			// 		this.minInvestment = value;
			// 	}
			// },

			// updateMaxInvestment(event) {
			// 	const value = parseInt(event.target.value);
			// 	if (value < this.minInvestment + 20000) {
			// 		this.maxInvestment = this.minInvestment + 20000;
			// 	} else {
			// 		this.maxInvestment = value;
			// 	}
			// },

			formatNumber(number) {
				return new Intl.NumberFormat("es-ES", {
					style: "decimal",
					minimumFractionDigits: 0,
					maximumFractionDigits: 2,
					useGrouping: true,
				}).format(number);
			},

			openModal(item) {
				this.selectedItem = item;
				this.showModal = true;
				this.$nextTick(() => {
					new Swiper(".swiper-container", {
						loop: true,
						navigation: {
							nextEl: ".swiper-button-next",
							prevEl: ".swiper-button-prev",
						},
					});
				});
			},
			closeModal() {
				this.showModal = false;
				this.selectedItem = null;
			},

			prev() {
				if (this.meta.current_page > 1) {
					this.fetchData(this.meta.current_page - 1);
				}
			},

			next() {
				if (this.meta.current_page < this.meta.last_page) {
					this.fetchData(this.meta.current_page + 1);
				}
			},

			goToPage(page) {
				if (page !== this.meta.current_page) {
					this.fetchData(page);
				}
			},
		},
	};
</script>

<style scoped>
	.loader {
		border: 3px solid #f3f3f3;
		border-top: 3px solid #3498db;
		border-radius: 50%;
		width: 18px;
		height: 18px;
		animation: spin 1s linear infinite;
		display: inline-block;
	}

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}

	.apply {
		background-color: #ff6155;
		color: #fff;
		width: 100%;
		height: 40px;
		font-size: 16px;
		border-radius: 15px;
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 10px;
		position: relative;
	}

	.theme_common_box_two {
		width: 320px;
		height: max-content;
		border-radius: 15px;
		box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
		overflow: hidden;
		background-color: white;
		font-family: Arial, sans-serif;
	}

	.theme_two_box_img {
		position: relative;
		overflow: hidden;
		border-top-left-radius: 15px;
		border-top-right-radius: 15px;
	}

	.theme_two_box_img img {
		width: 100%;
		height: 220px;
		object-fit: cover;
		border-top-left-radius: 15px;
		border-top-right-radius: 15px;
		cursor: pointer;
	}

	.theme_two_box_content {
		padding: 10px 15px;
		text-align: left;
		margin-top: -5px;
	}

	.theme_two_box_content h4 {
		font-size: 18px;
		font-weight: bold;
		color: #333;
		margin: 5px 0;
	}

	.theme_two_box_content .score_rating .score_star_all i {
		color: #5d69d5;
		font-size: 14px;
	}

	.theme_two_box_content .review_rating .review_star_all i {
		color: #ffbc00;
		font-size: 14px;
	}

	.theme_two_box_content .text-blue-violet {
		font-size: 20px;
		font-weight: bold;
		color: #5d69d5;
		margin: 10px 0;
	}

	.theme_two_box_content .fs-4 {
		font-size: 14px;
		color: #333;
	}

	.theme_two_box_content .review_count {
		font-size: 14px;
		color: #333;
	}

	.review_star_all .fas.fa-star {
		color: #5d69d5;
		margin-left: 2px;
	}

	.review-star-color {
		color: #f1c40f;
	}

	.hotel-star-color {
		color: #5d69d5;
	}

	.v-select {
		height: auto;
	}

	.v-select .vs__dropdown-menu {
		max-height: 200px;
		overflow-y: auto;
	}

	.v-select .vs__selected-options {
		display: flex;
		flex-wrap: wrap;
		gap: 5px;
		max-height: 200px;
		overflow-y: auto;
	}

	.modal-overlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.7);
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 1000;
		backdrop-filter: blur(5px);
	}

	.modal-content {
		background: white;
		padding: 20px;
		border-radius: 15px;
		width: 90%;
		max-width: 600px;
		box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
		position: relative;
	}

	.modal-header {
		display: flex;
		justify-content: flex-end;
	}

	.close-btn {
		background: none;
		border: none;
		font-size: 22px;
		cursor: pointer;
		color: #333;
	}

	.swiper-container {
		width: 100%;
		height: 400px;
		margin-bottom: 20px;
		border-radius: 10px;
		overflow: hidden;
	}

	.swiper-slide img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.swiper-button-next,
	.swiper-button-prev {
		color: white;
		background: rgba(0, 0, 0, 0.5);
		padding: 10px;
		border-radius: 50%;
		width: 40px;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		top: 50% !important;
	}
	.modal-info {
		padding: 20px;
	}

	.title-section {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 15px;
	}

	.title-section h2 {
		font-size: 24px;
		margin: 0;
		color: #333;
	}

	.contact-icons {
		display: flex;
		gap: 10px;
	}

	.contact-icon {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 36px;
		height: 36px;
		border-radius: 50%;
		text-decoration: none;
		color: white;
		font-size: 16px;
		transition: background-color 0.3s ease;
	}

	.contact-icon.whatsapp {
		background-color: #25d366;
	}

	.contact-icon.website {
		background-color: #5d69d5;
	}

	.contact-icon:hover {
		opacity: 0.9;
	}

	.hover-text {
		position: absolute;
		right: 40px;
		white-space: nowrap;
		background-color: rgba(0, 0, 0, 0.8);
		color: white;
		padding: 5px 10px;
		border-radius: 5px;
		font-size: 14px;
		opacity: 1;
		transition: opacity 0.3s ease;
	}

	.modal-info p {
		font-size: 16px;
		margin-bottom: 10px;
		color: #555;
	}

	.modal-info .category {
		font-weight: bold;
		color: #5d69d5;
	}

	.modal-info .location {
		color: #333;
	}

	.modal-info .investment {
		color: #333;
	}

	.modal-info .phone {
		color: #333;
	}

	.fab.fa-whatsapp,
	.fas.fa-external-link-alt {
		color: white;
	}

	.fas.fa-map-marker-alt {
		font-size: 1.5em;
		color: #ff0000;
	}
	.multi-range {
		position: relative;
		height: 50px;
	}

	.position_ball {
		position: absolute;
		top: -23px;
		left: 0;
	}

	input[type="range"] {
		box-sizing: border-box;
		appearance: none;
		width: 100%;
		margin: 0;
		padding: 0 2px;
		overflow: hidden;
		border: 0;
		border-radius: 1px;
		outline: none;
		background: linear-gradient(grey, grey) no-repeat center;
		background-size: 100% 2px;
		pointer-events: none;
	}

	input[type="range"]:active,
	input[type="range"]:focus {
		outline: none;
	}

	input[type="range"]::-webkit-slider-thumb {
		height: 28px;
		width: 28px;
		border-radius: 28px;
		background-color: #ff6155;
		position: relative;
		margin: 5px 0;
		cursor: pointer;
		appearance: none;
		pointer-events: all;
		box-shadow: 0 1px 4px 0.5px rgba(0, 0, 0, 0.25);
	}

	.multi-range input[type="range"]:nth-child(1)::-webkit-slider-thumb::before {
		background-color: red;
	}

	.multi-range input[type="range"]:nth-child(2) {
		background: none;
	}

	.multi-range input[type="range"]:nth-child(2)::-webkit-slider-thumb::before {
		background-color: grey;
	}

	.range-values {
		display: flex;
		justify-content: space-between;
		margin-top: 10px;
	}
</style>
