<template>
	<section id="common_author_area" class="section_padding">
		<div class="container">
			<div class="row">
				<div class="col-lg-10 offset-lg-1">
					<div class="common_author_boxed">
						<div class="common_author_heading">
							<h2>{{ $t("register_place_interest") }}</h2>
							<small>{{ $t("copy_place_interest") }}</small>
						</div>
						<div class="common_author_form">
							<form id="main_author_form" @input="validateRecaptcha">
								<div class="row">
									<div class="form-group col-lg-12">
										<input
											type="text"
											class="form-control"
											:placeholder="$t('commercial_name')"
											v-model="form.commercial_name"
											required
										/>
									</div>
									<div class="form-group col-lg-6">
										<v-select
											class="form-control"
											v-model="categorySelected"
											label="text"
											:options="categories"
											:filterable="true"
											:placeholder="$t('category_2')"
											required
										></v-select>
									</div>
									<div class="form-group col-lg-6">
										<v-select
											class="form-control"
											v-model="subCategorySelected"
											label="text"
											:options="subcategories"
											:filterable="true"
											:placeholder="$t('subcategory')"
											required
										></v-select>
									</div>

									<div class="form-group">
										<input
											type="text"
											class="form-control"
											:placeholder="$t('address')"
											v-model="form.address"
											required
										/>
									</div>
									<div class="form-group col-lg-6">
										<input
											type="text"
											class="form-control"
											:placeholder="$t('average_investment')"
											v-model="form.average_investment"
											@input="formatNumber"
											required
										/>
									</div>
									<div class="form-group col-lg-6">
										<input
											type="text"
											class="form-control"
											:placeholder="$t('contact_link')"
											v-model="form.contact_link"
											required
										/>
									</div>
									<div class="form-group col-lg-6">
										<input
											type="text"
											class="form-control"
											:placeholder="$t('phone_2')"
											v-model="form.phone"
											required
										/>
									</div>
									<div class="form-group col-lg-6">
										<input
											type="text"
											class="form-control"
											placeholder="Whatsapp"
											v-model="form.whatsapp"
											required
										/>
									</div>
									<div class="form-group col-lg-6">
										<v-select
											class="form-control"
											v-model="citySelected"
											label="text"
											:options="cities"
											:filterable="true"
											:placeholder="$t('city')"
											required
										></v-select>
									</div>
									<div class="form-group col-lg-6">
										<button
											type="button"
											@click="openMapModal"
											class="form-control btn btn_theme btn_md"
										>
											{{ $t("select_location_on_map") }}
										</button>
									</div>
									<div class="form-group col-lg-12">
										<label for="photos">{{
											$t("upload_photos_of_the_place")
										}}</label>
										<input
											type="file"
											class="form-control"
											id="photos"
											accept="image/*"
											multiple
											@change="handleFileUpload"
										/>
										<div class="photo-previews">
											<div
												v-for="(photo, index) in photos"
												:key="index"
												class="photo-preview"
											>
												<img
													:src="getPhotoURL(photo)"
													alt="Vista previa de la foto"
												/>
												<button
													@click="removePhoto(index)"
													class="remove-photo-btn"
												>
													×
												</button>
											</div>
										</div>
									</div>
									<div class="common_author_heading">
										<h2>{{ $t("owner_information") }}</h2>
										<br />
									</div>

									<div class="form-group col-lg-6">
										<input
											type="text"
											class="form-control"
											:placeholder="$t('full_name')"
											v-model="form.name"
											required
										/>
									</div>
									<div class="form-group col-lg-6">
										<v-select
											class="form-control"
											v-model="documentSelected"
											label="text"
											:options="type_document"
											:filterable="true"
											:placeholder="$t('type_person')"
											required
										></v-select>
									</div>

									<div class="form-group col-lg-6">
										<input
											type="text"
											class="form-control"
											:placeholder="$t('identification_number')"
											v-model="form.identification_number"
											required
										/>
									</div>

									<div class="form-group col-lg-6">
										<input
											type="text"
											class="form-control"
											:placeholder="$t('main_phone')"
											v-model="form.main_phone"
											required
										/>
									</div>
									<div class="form-group col-lg-6">
										<input
											type="text"
											class="form-control"
											:placeholder="$t('secondary_phone')"
											v-model="form.secondary_phone"
										/>
									</div>
									<div class="form-group col-lg-6">
										<input
											type="email"
											class="form-control"
											:placeholder="$t('email')"
											v-model="form.email"
											required
										/>
									</div>
								</div>

								<div class="form-check write_spical_check">
									<input
										class="form-check-input"
										type="checkbox"
										v-model="form.acceptTerms"
										id="flexCheckDefaultf1"
									/>
									<label
										class="form-check-label"
										for="flexCheckDefaultf1"
										@click="openInNewTab()"
									>
										<span class="main_spical_check">
											<span />
											{{ $t("i_accept_terms_and_conditions") }}
										</span>
									</label>
								</div>
								<div class="common_form_submit">
									<button
										type="button"
										class="btn btn_theme btn_md"
										@click="registerPlace"
									>
										{{ $t("register") }}
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="showMapModal" class="modal-overlay">
			<div class="modal-content">
				<div class="modal-header">
					<h3>{{ $t("select_a_location_on_the_map") }}</h3>
					<button @click="closeMapModal" class="close-btn">&times;</button>
				</div>
				<div class="modal-body">
					<div
						id="map"
						style="height: 100%; width: 100%; background-color: black"
					></div>
				</div>
				<div class="modal-footer">
					<button @click="confirmLocation" class="btn btn_theme btn_md">
						{{ $t("confirm_location") }}
					</button>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	import axios from "axios";
	import { mapActions } from "vuex";
	import {
		API_BASE_URL,
		PLACE_INTEREST_API,
		PLACE_CATEGORIES_API,
		PLACE_SUBCATEGORIES_API,
		TYPE_DOCUMENT_API,
		CITY_API,
	} from "@/api-config";
	import Swal from "sweetalert2";
	import { loadGoogleMapsScript } from "@/config/googleMaps";
	import { useReCaptcha } from "vue-recaptcha-v3";

	export default {
		name: "CommonAuthorSix",
		data() {
			return {
				categories: [],
				subcategories: [],
				type_document: [],
				cities: [],
				categorySelected: null,
				subcategorySelected: null,
				documentSelected: null,
				citySelected: null,
				form: {
					commercial_name: "",
					category: "",
					subcategory: "",
					department: "",
					city: "",
					address: "",
					latitude: "",
					longitude: "",
					average_investment: "",
					phone: "",
					whatsapp: "",
					contact_link: "",
					images: [],
					name: "",
					type_person: "",
					identification_number: "",
					main_phone: "",
					secondary_phone: "",
					email: "",
					acceptTerms: false,
				},
				showMapModal: false,
				map: null,
				marker: null,
				defaultLocation: { lat: 6.245683, lng: -75.5678843 },
				selectedLocation: null,
				photos: [],
				recaptchaVerified: false,
				recaptchaSiteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
			};
		},
		setup() {
			const { executeRecaptcha } = useReCaptcha();
			return { executeRecaptcha };
		},

		async mounted() {
			this.getCategories();
			this.getTypeDocument();
			this.getCities();
			await loadGoogleMapsScript();
			this.selectedLocation = this.defaultLocation;
		},

		watch: {
			categorySelected(newCategory) {
				if (newCategory) {
					this.getSubcategories(newCategory.id);
				} else {
					this.subcategories = [];
				}
			},
		},
		methods: {
			...mapActions("form", ["updateError"]),

			async validateRecaptcha() {
				try {
					const token = await this.executeRecaptcha("common_author_form");
					if (token) {
						this.recaptchaVerified = true;
					} else {
						this.recaptchaVerified = false;
					}
				} catch (error) {
					console.error("reCAPTCHA failed", error);
					this.recaptchaVerified = false;
				}
			},

			handleFileUpload(event) {
				const files = event.target.files;
				if (files.length > 4) {
					this.showAlert(
						this.$t("you_can_only_upload_a_maximum_of_4_photos"),
						"error"
					);
					event.target.value = "";
					return;
				}

				const newPhotos = Array.from(files);
				const totalPhotos = this.photos.length + newPhotos.length;

				if (totalPhotos > 4) {
					this.showAlert(
						this.$t("you_can_only_upload_a_maximum_of_4_photos"),
						"error"
					);
					event.target.value = "";
					return;
				}

				this.photos = [...this.photos, ...newPhotos];
				event.target.value = "";
			},

			getPhotoURL(file) {
				return URL.createObjectURL(file);
			},

			removePhoto(index) {
				this.photos.splice(index, 1);
				const fileInput = document.getElementById("photos");
				if (fileInput) {
					fileInput.value = "";
				}
			},
			getCategories() {
				axios
					.get(`${API_BASE_URL}${PLACE_CATEGORIES_API}`)
					.then((response) => {
						this.categories = response.data.results;
					})
					.catch((error) => {
						console.log(error);
					});
			},
			getSubcategories(categoryId) {
				axios
					.get(
						`${API_BASE_URL}${PLACE_SUBCATEGORIES_API}?category_id=${categoryId}`
					)
					.then((response) => {
						this.subcategories = response.data.results;
					})
					.catch((error) => {
						console.log(error);
					});
			},
			getTypeDocument() {
				axios
					.get(`${API_BASE_URL}${TYPE_DOCUMENT_API}`)
					.then((response) => {
						this.type_document = response.data.results;
					})
					.catch((error) => {
						console.log(error);
					});
			},

			getCities() {
				axios
					.get(`${API_BASE_URL}${CITY_API}`)
					.then((response) => {
						this.cities = response.data.results;
					})
					.catch((error) => {
						console.log(error);
					});
			},

			openMapModal() {
				this.showMapModal = true;
				this.$nextTick(() => {
					this.initMap();
				});
			},

			closeMapModal() {
				this.showMapModal = false;
			},

			initMap() {
				const mapElement = document.getElementById("map");
				this.map = new google.maps.Map(mapElement, {
					center: this.defaultLocation,
					zoom: 15,
					mapTypeControlOptions: {
						mapTypeIds: ["roadmap"],
					},
					streetViewControl: false,
					fullscreenControl: false,
					zoomControl: false,
				});

				this.marker = new google.maps.Marker({
					position: this.defaultLocation,
					map: this.map,
				});

				this.selectedLocation = this.defaultLocation;

				this.map.addListener("click", (event) => {
					this.marker.setPosition(event.latLng);
					this.selectedLocation = event.latLng;
				});
			},

			confirmLocation() {
				const location = this.selectedLocation || this.defaultLocation;
				this.form.latitude = location.lat();
				this.form.longitude = location.lng();
				this.closeMapModal();
			},

			showAlert(message, type) {
				Swal.fire({
					icon: type,
					title: type === "success" ? "Éxito" : "Error",
					text: message,
				}).then(() => {
					this.updateError({ status: false, message: "" });
				});
			},

			validateForm() {
				if (!this.form.acceptTerms) {
					return this.$t("please_accept_terms_and_conditions");
				}
				return null;
			},

			resetForm() {
				this.form = {
					commercial_name: "",
					category: "",
					subcategory: "",
					department: "",
					city: "",
					address: "",
					latitude: "",
					longitude: "",
					average_investment: "",
					phone: "",
					whatsapp: "",
					contact_link: "",
					images: [],
					name: "",
					type_person: "",
					identification_number: "",
					main_phone: "",
					secondary_phone: "",
					email: "",
					acceptTerms: false,
				};

				this.categorySelected = null;
				this.subCategorySelected = null;
				this.documentSelected = null;
				this.citySelected = null;

				this.photos = [];

				this.selectedLocation = this.defaultLocation;
			},

			registerPlace() {
				if (!this.recaptchaVerified) {
					this.showAlert(this.$t("please_resolve_captcha"), "error");
					return;
				}
				const validationError = this.validateForm();
				if (validationError) {
					this.showAlert(validationError, "error");
					return;
				}

				const formData = new FormData();

				formData.append("commercial_name", this.form.commercial_name);
				formData.append("place_category_id", this.categorySelected.id);
				formData.append("place_sub_category_id", this.subCategorySelected.id);
				formData.append("city_id", this.citySelected.id);
				formData.append("address", this.form.address);
				formData.append("latitude", this.form.latitude);
				formData.append("longitude", this.form.longitude);
				formData.append("average_investment", this.form.average_investment);
				formData.append("phone", this.form.phone);
				formData.append("whatsapp", this.form.whatsapp);
				formData.append("contact_link", this.form.contact_link);

				this.photos.forEach((photo, index) => {
					formData.append(`images[${index}][image]`, photo);
				});

				formData.append("name", this.form.name);
				formData.append("type_document_id", this.documentSelected.id);
				formData.append("document", this.form.identification_number);
				formData.append("main_phone", this.form.main_phone);
				formData.append("secondary_phone", this.form.secondary_phone);
				formData.append("email", this.form.email);

				axios
					.post(`${API_BASE_URL}${PLACE_INTEREST_API}`, formData, {
						headers: {
							"Content-Type": "multipart/form-data",
						},
					})
					.then((response) => {
						if (response.data && response.data.message) {
							const successMessage = response.data.message;
							this.showAlert(successMessage, "success");

							if (response.data.payable === 1) {
								this.$router.push({
									name: "license-selection",
									params: { id: response.data.id },
								});
							} else {
								this.resetForm();
							}
						}
					})
					.catch((error) => {
						if (
							error.response &&
							error.response.data &&
							error.response.data.message
						) {
							const errorMessage = error.response.data.message;
							this.showAlert(errorMessage, "error");
						} else {
							this.showAlert("Error desconocido");
						}
					});
			},

			openInNewTab() {
				const url = this.$router.resolve({
					name: "terms-service",
				}).href;

				window.open(url, "_blank", "noopener,noreferrer");
			},
		},
	};
</script>

<style scoped>
	.photo-previews {
		display: flex;
		flex-wrap: wrap;
		gap: 10px;
		margin-top: 10px;
	}

	.photo-preview {
		position: relative;
		width: 100px;
		height: 100px;
		border: 1px solid #ddd;
		border-radius: 4px;
		overflow: hidden;
	}

	.photo-preview img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.remove-photo-btn {
		position: absolute;
		top: 5px;
		right: 5px;
		background: red;
		color: white;
		border: none;
		border-radius: 50%;
		width: 20px;
		height: 20px;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 12px;
	}
	.modal-overlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.5);
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 1000;
	}

	.modal-content {
		background: white;
		padding: 35px;
		border-radius: 8px;
		width: 80%;
		height: 80%;
		margin-top: 50px;
	}

	@media (min-width: 768px) {
		.modal-content {
			width: 80%;
		}
	}

	@media (min-width: 1024px) {
		.modal-content {
			width: 80%;
		}
	}

	.modal-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 20px;
	}

	.close-btn {
		background: none;
		border: none;
		font-size: 24px;
		cursor: pointer;
	}

	.modal-footer {
		margin-top: 20px;
		text-align: right;
	}
</style>
