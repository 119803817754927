<template>
	<!-- Banner Area -->
	<Banner />

	<!-- Form Area -->
	<Form />

	<InterestPlaces
		v-if="items.length > 0"
		:items="items"
		:lat="lat"
		:lng="lng"
	/>

	<!-- imagination Area -->
	<Imagination />

	<!-- Top destinations -->
	<TopDestinations />

	<!-- <OutstandingProperties /> -->

	<!-- <RecommendedProperties /> -->

	<NewsArea />

	<Testimonials />
</template>

<script>
	// @ is an alias to /src
	import Banner from "@/components/home/Banner.vue";
	import Form from "@/components/home/Form.vue";
	import InterestPlaces from "@/components/home/InterestPlaces.vue";
	import Imagination from "@/components/home/Imagination.vue";
	import TopDestinations from "@/components/home/TopDestinations.vue";
	import RecommendedProperties from "@/components/home/RecommendedProperties.vue";
	import OutstandingProperties from "@/components/home/OutstandingProperties.vue";
	import NewsArea from "@/components/home/NewsArea.vue";
	import Testimonials from "@/components/pages/Testimonials.vue";
	import axios from "axios";
	import { API_BASE_URL, HOME_INTEREST_SITES } from "@/api-config";

	export default {
		name: "HomeView",
		components: {
			Banner,
			Form,
			InterestPlaces,
			Imagination,
			TopDestinations,
			RecommendedProperties,
			OutstandingProperties,
			NewsArea,
			Testimonials,
		},
		data() {
			return {
				items: [],
				lat: null,
				lng: null,
			};
		},
		mounted() {
			this.getLocation();
		},
		methods: {
			async getLocation() {
				const cachedLocation = this.getLocationFromLocalStorage();
				if (cachedLocation) {
					this.lat = cachedLocation.lat;
					this.lng = cachedLocation.lng;
					this.fetchInterestPlaces();
					return;
				}

				const apiKey = process.env.VUE_APP_GOOGLE_API_KEY;
				const url = `https://www.googleapis.com/geolocation/v1/geolocate?key=${apiKey}`;

				try {
					const response = await fetch(url, {
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify({}),
					});

					if (!response.ok) throw new Error("Error al obtener la ubicación");

					const data = await response.json();
					this.lat = data.location.lat;
					this.lng = data.location.lng;

					this.saveLocationToLocalStorage(this.lat, this.lng);

					this.fetchInterestPlaces();
				} catch (error) {
					console.error("Error al obtener la ubicación:", error);
				}
			},

			getLocationFromLocalStorage() {
				const location = localStorage.getItem("userLocation");
				if (location) {
					return JSON.parse(location);
				}
				return null;
			},

			saveLocationToLocalStorage(lat, lng) {
				const location = { lat, lng };
				localStorage.setItem("userLocation", JSON.stringify(location));
			},

			fetchInterestPlaces() {
				if (this.lat && this.lng) {
					const url = `${API_BASE_URL}${HOME_INTEREST_SITES}?latitude=${this.lat}&longitude=${this.lng}`;
					axios
						.get(url)
						.then((response) => {
							this.items = response.data.data;
						})
						.catch((error) => {
							console.error("Error fetching properties:", error);
						});
				} else {
					console.error("Latitud y longitud no disponibles");
				}
			},
		},
	};
</script>
